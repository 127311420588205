
function handleAjaxAnswer(reqObj, arg2, arg3) {
    var status = typeof arg2 == 'string' ? arg2 : 'success'; // [success|notmodified|error|timeout|abort|parsererror]
    var afterCall = typeof arg3 == 'function' ? arg3 : arg2;
    
    if( status=='abort' ) return;
    
    try {
        //MUST BE LOGED
        if( reqObj.getResponseHeader('x-must-be-loged') == 1 ) {
            errorModal(__('session_expired_title'), __('session_expired_text'), {
                height:275,
                width:450,
                zIndex:2000,
                buttons: {
                    Connexion: function() {
                        if( typeof doLogout != 'undefined' ) doLogout();
                        else window.location.reload(true);
                    },
                    Fermer: function() { $(this).dialog('close'); }
                }
            });
        } else {
            //PARSE ANSWER
            var $answer = jQuery.parseJSON( reqObj.responseText );
            if( status == 'success' && typeof $answer != 'undefined' && typeof afterCall == 'function' ) {
                afterCall(reqObj, new AjaxAnswer($answer));
            } else if( status != 'abort' ) {
                unknownError();
            }
        }
    }
    catch(e){
        unknownError();
        // console.error(e.toString());
        throw e;
    }
}

var AjaxAnswer = function(json){
    var json = json || {};
    this.content = typeof json.content != 'undefined' ? json.content : '';
    this.errors = typeof json.errors != 'undefined' ? json.errors : [];
};

AjaxAnswer.prototype.getErrors = function(){ return this.errors; }
AjaxAnswer.prototype.getContent = function(path){
    if( typeof path == 'undefined' ) {
        return this.content;
    }
    
    if( typeof eval('this.content.' + path) != 'undefined' ) {
        return eval('this.content.' + path);
    }
};
