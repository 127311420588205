
$.fn.initFilters = function(custom_options) {
    var $filters = $(this);

    var default_options = {
        getFilters: getFilters,
        restrictFilters: function(info, from_init) {
            $filters.find(".filterInput-disabled").removeClass("filterInput-disabled");

            if (!from_init) {
                $filters.find(".js-filterInput").each(function() {
                    var $input = $(this);
                    var filter_type = $(this).data("filter_type");
                    var filter_value = $(this).data("filter_value");

                    if (info[filter_type][filter_value] == 0) {
                        $input.addClass("filterInput-disabled");
                    }
                });
            }
        }
    };

    var options = $.extend(default_options, custom_options);

    function setFilters() {
        $filters.imarcomSearch(options);
        $filters.imarcomSearch("filter");

        var $nb_results = $(".js-filters-nbResults");

        if ($nb_results.length) {
            if ($filters.find(".filterSelected").length) {
                $filters.find(".js-filters-reset").addClass("filters-reset-active");
                $nb_results.removeClass("filters-nbResults-hided");
                $nb_results.find(".filters-nbResults-nb").text(options.$elements.filter(".filtered").length);
            } else {
                $filters.find(".js-filters-reset").removeClass("filters-reset-active");
                $nb_results.addClass("filters-nbResults-hided");
                $nb_results.find("filters-nbResults-nb").text("");
            }
        }

        // add "on command" tag
        options.$elements.find('.js-onCommandTag').addClass('hidden');
        $('.js-filter-finish .js-filterInput.filterSelected').each(function() {
            let $that = $(this);
            options.$elements.each(function(){
                if($(this).data('finish_on_command').indexOf($that.data('filter_value')) !== -1) {
                    $(this).find('.js-onCommandTag').removeClass('hidden');
                }
            });
        });
    }

    function updateFilters() {
        $filters.imarcomSearch("destroy");
        setFilters();
        updateUrl();
    }

    function updateUrl() {
        var API = $filters.data("api");
        _filters = API.getFilters();

        if (!$.isEmptyObject(_filters)) {
            history.replaceState(undefined, undefined, "#" + URLON.stringify(_filters));
            // window.location.replace = URLON.stringify(_filters);
        } else {
            history.replaceState(undefined, undefined, "#");
            // window.location.replace = "";
        }

        updateHrefs();
    }

    function getFilters() {
        var selected_filters = {};

        $filters.find(".js-filterInput").each(function() {
            if ($(this).hasClass("filterSelected")) {
                var filter_type = $(this).data("filter_type");
                var filter_value = $(this).data("filter_value");

                if (!selected_filters.hasOwnProperty(filter_type)) {
                    selected_filters[filter_type] = [];
                }

                selected_filters[filter_type].push(filter_value);
            }
        });

        return selected_filters;
    }

    function updateHrefs() {
        $(".js-inspirationMosaic-elementHrefToChange").each(function() {
            var href = $(this).data("href");
            var filters = '';
            var hash = window.location.hash.replace(/^#/,'');

            if (hash != "") {
                var options_from_url = URLON.parse(hash);
                var temp_filters = [];

                $.each(options_from_url, function(key, values) {
                    temp_filters.push(key +"=" + options_from_url[key].join("---"));
                })

                filters = "?" + temp_filters.join("&");
            }

            $(this).attr("href", href + filters);
        });
    }

    $filters.find(".js-filterInput").on("click", function() {
        if (!$(this).hasClass("filterInput-disabled")) {
            $(this).toggleClass("filterSelected");
            updateFilters();

            var filter_type = $(this).data("filter_type");
            var filter_value = $(this).data("filter_value");

            if ($(this).hasClass("filterSelected")) {
                if ($(".js-filters-selectedFilters").length) {
                    var filter_name = $(this).text();

                    $(".js-filters-selectedFilters").append('<a class="filters-selectedFilter" href="javascript:;" data-filter_type="'+filter_type+'" data-filter_value="'+filter_value+'">'+filter_name+'&nbsp;&nbsp;&nbsp;X</a>');
                }
            } else {
                var $selected_filter = $filters.find('.filters-selectedFilter[data-filter_type="'+filter_type+'"][data-filter_value="'+filter_value+'"]');

                if ($selected_filter.length) {
                    $selected_filter.remove();
                }
            }
        }
    });

    $filters.find(".js-filters-listToggler").on("click", function() {
        var $list = $filters.find(".filters-list");

        if (!$list.data("height")) {
            $list.css("height", "auto");
            $list.data("height", $list.height());
            $list.css("height", 0);
        }

        if (!$list.hasClass("filters-list-opened")) {
            $list.addClass("filters-list-opened")
            $list.stop().animate({
                "height": $list.data("height")
            }, 300, function() {
                $list.css("height", "auto");
            });
        } else {
            $list.removeClass("filters-list-opened");
            $list.stop().animate({
                "height": 0
            }, 300);
        }
    });

    doEventPeriodically($(window), "resize", 200, function() {
        var $list = $filters.find(".filters-list");
        $list.data("height", $list.height());
    });

    $filters.find(".js-filters-reset").on("click", function() {
        $filters.find(".filterSelected").removeClass("filterSelected");
        $(".js-filters-selectedFilters").html("");
        updateFilters();
    });

    $filters.find(".js-filters-selectedFilters").on("click", ".filters-selectedFilter", function() {
        var filter_type = $(this).data("filter_type");
        var filter_value = $(this).data("filter_value");

        $filters.find('.js-filterInput[data-filter_type="'+filter_type+'"][data-filter_value="'+filter_value+'"]').trigger("click");
    });

    $filters.find(".js-filter-inline").each(function() {
        var width = 0;

        $(this).find(".js-filterInput").each(function() {
            var _width = $(this).outerWidth();
            if (_width > width) {
                width = _width;
            }
        });

        $(this).find(".js-filterInput").css("width", width);
    });

    setFilters();

    var hash = window.location.hash.replace(/^#/,'');

    if (hash != "") {
        var options_from_url = URLON.parse(hash);

        $.each(options_from_url, function(filter_type, values) {
            $.map(values, function(filter_value) {
                $filters.find('.js-filterInput[data-filter_type="'+filter_type+'"][data-filter_value="'+filter_value+'"]').trigger("click");
            });
        });
    }
}
