
$.ajaxSetup({
    headers: {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
    }
});

jQuery.fn.raymondSearch = function() {

    var $search = $(this);
    var $searchForm = $(this).find("form");
    var $searchResults = $(this).find("#js-search-results");
    var pathSearch = "/search/";
    var $searchInput = $("#js-search-input");
    var $searchCategories = $("#js-search-results-categories");
    var $searchProducts = $("#js-search-results-products");
    var $searchNoResults = $("#js-search-results-empty");

    init();

    function resetSearch() {
        $searchCategories.hide();
        $searchProducts.hide();
        $searchNoResults.hide();
        $(".js-search-results").html("");
    }

    function init() {

        resetSearch();

        $searchInput.focus();

        $searchForm.on("submit", function(e){
            e.preventDefault();
            search($searchInput.val());
        });

        var hash = window.location.hash.replace(/^#/,'');
        if(hash.indexOf("search=") > -1) {

            var query = URLON.parse(hash.substr(6));
            search(query);
            $searchInput.val(query);
        }
    }

    function search(query) {

        var hash = "";
        resetSearch();

        if(query != "") {

            $searchForm.imarcomLoader();

            $.ajax({
                type: 'POST',
                url: "/search/",
                data: {
                    query : query,
                    language : $('body').hasClass('lang_fr') ? 'fr' : 'en'
                },
                complete: function(reqObj) {

                    var result = JSON.parse(reqObj.responseText);
                    if(result.RaymondCategory.length > 0) {

                        $.each(result.RaymondCategory, function($a, $b) {
                            $searchCategories.find(".js-search-results").append($b);
                        });
                        $searchCategories.show();
                    }
                    if(result.RaymondProduct.length > 0) {
                        $.each(result.RaymondProduct, function($a, $b) {
                            $searchProducts.find(".js-search-results").append($b);
                        });
                        $searchProducts.show();
                    }
                    if(result.RaymondCategory.length == 0 && result.RaymondProduct.length == 0) {
                        $searchNoResults.show();
                    }

                    $searchForm.removeClass('working');
                    $searchForm.trigger('loader.destroy');
                },
                error: function(){
                    unknownError();
                    $searchForm.removeClass('working');
                    $searchForm.trigger('loader.destroy');
                }
            });

            hash = "search" + URLON.stringify(query);
        }

        window.location.hash = hash;
    }
}
