
(function($){
	var default_options = {
		display_time:		4000,
		transition_time:	2000,
		auto_swap:			true,
		repeat:				1,
		arrows_display:		false,
		
		selector: {
			menu :			'.imarcomSlideshow-menu',
			menu_elements:	'.imarcomSlideshow-menuElement',
			slides:			'.imarcomSlideshow-slide',
			arrows:			'.imarcomSlideshow-arrow'
		},
		
		onInit:		function(){},
		beforeSelect:		function(){},
		afterSelect:		function(){}
	};
	
	$.fn.imarcomSlideshow = function(options) {
		var $element = this;
		
        if ($element.length > 1) {
            $element.each(function() {
                $(this).imarcomSlideshow(options);
            });
            
            return $element;
        }
        
		var _super = this;
		var options = $.extend(true,{}, default_options, options);
		
		if (options.arrows_display) {
			$element.append('<div class="imarcomSlideshow-arrows"><a class="imarcomSlideshow-arrow imarcomSlideshow-leftArrow" href="javascript:;"></a><a class="imarcomSlideshow-arrow imarcomSlideshow-rightArrow" href="javascript:;"></a></div>');
		}
		
		var $DHL = $(this),
			$menu = $(options.selector.menu, $DHL),
			$menu_elements = $(options.selector.menu_elements, $DHL),
			$slides = $(options.selector.slides, $DHL),
			$arrows = $(options.selector.arrows, $DHL),
			count = $menu_elements.length,
			selected_id = null,
			intervalID;
		
		options.onInit.call(_super, getUI());
			
		$menu_elements.bind('click', function(e){
			if (selected_id != $menu_elements.index($(this))) {
				e.preventDefault();
				disableAutoSwap();
				selectSlide($menu_elements.index($(this)));
			}
		});
		
		$arrows.bind('click', function(e){
			e.preventDefault();
			disableAutoSwap();
			
			var direction = 1;
			if ($(this).hasClass('imarcomSlideshow-leftArrow')) {
				direction = -1;
			}
			
			var id = selected_id + direction;
			if (id < 0) id = $menu_elements.length - 1;
			if (id > $menu_elements.length - 1) id = 0;
			
			selectSlide(id);
		});
		
		selectSlide(0);
		
		options.auto_swap && activateAutoSwap();
		
		function selectSlide(id) {
			if( id==selected_id ) return;
			var ui = getUI();
				ui.next_id = id;
			
			options.beforeSelect.call(_super, ui);
			
			var previous_ui = selected_id;
			selected_id = id;
			
			ui = getUI();
			ui.previous_ui = previous_ui;
			
			if( typeof options.onSelect == 'function' ) {
				options.onSelect.call(_super, ui);
			} else {
				$menu_elements.removeClass('imarcomSlideshow-menuElement-active');
				$menu_elements.eq(selected_id).addClass('imarcomSlideshow-menuElement-active');
				$slides.removeClass('imarcomSlideshow-slide-active');
				$slides.eq(selected_id).addClass('imarcomSlideshow-slide-active');
			}
			
			options.afterSelect.call(_super, ui);
		}
		function getUI() {
			return {
				menu: $menu,
				menu_elements: $menu_elements,
				slides: $slides,
				selected_id: selected_id
			};
		}
		
		function activateAutoSwap() {
			intervalID = window.setInterval(function(){
				selectSlide((selected_id+1)%$menu_elements.length);
			}, options.display_time);
		}
		
		function disableAutoSwap() {
			window.clearInterval(intervalID);
		}
		
		return this;
	};
	
})(jQuery);
