$.ajaxSetup({
    headers: {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
    }
});

var scrollTopOffset = 0;

function updateScrollTopOffset() {
    if (true) {
        // HEADER EST FIXED
        scrollTopOffset = $('header').height();
    } else {
        // HEADER N'EST PAS FIXED
        scrollTopOffset = 0;
    }
}

Date.now = Date.now || function() { return +new Date; };

var ie8_div = document.createElement("div");
ie8_div.innerHTML = "<!--[if lt IE 9]><i></i><![endif]-->";
var isIe8LESS = (ie8_div.getElementsByTagName("i").length == 1);

function getClientInfo() {
    var viewportwidth, viewportheight;

    if( typeof window.innerWidth != 'undefined' ) {
        viewportwidth = window.innerWidth,
        viewportheight = window.innerHeight
    }
    // IE6 in standards compliant mode (i.e. with a valid doctype as the first line in the document)
    else if ( typeof document.documentElement != 'undefined' && typeof document.documentElement.clientWidth != 'undefined' && document.documentElement.clientWidth != 0) {
        viewportwidth = document.documentElement.clientWidth,
        viewportheight = document.documentElement.clientHeight
    }
    // older versions of IE
    else {
        viewportwidth = document.getElementsByTagName('body')[0].clientWidth,
        viewportheight = document.getElementsByTagName('body')[0].clientHeight
    }

    return {
        'navigator.appVersion' : navigator.appVersion,
        'navigator.appName' : navigator.appName,
        'navigator.appCodeName' : navigator.appCodeName,
        'navigator.platform' : navigator.platform,
        'history.length' : history.length,
        'navigator.javaEnabled' : navigator.javaEnabled() ? 'yes' : 'no',
        'screen.width' : screen.width,
        'screen.height' : screen.height,
        'viewport.width' : viewportwidth,
        'viewport.height' : viewportheight,
        'referer': document.referrer,
        'querystring': (window.location.href.indexOf('?') !== -1 ? window.location.href.split('?')[1] : '')
    };
}

function getClientInfoSerialised() {
    return $.param(getClientInfo());
}

function antiJunkMail () {
    $("a[rel='antijunk_email']").each(function(index){
        /**
         * changed in order get get rid of duplicated id
         *
         * OLD <a rel="antijunk_email" id="username" class="domain">&nbsp;</a>
         * NEW <a rel="antijunk_email" name="username" rev="domain">&nbsp;</a>
         */
        $this = $(this);

        var mail = $this.attr('name') + '@' + $this.attr('rev');
        var subject = $this.attr('title') || '';

        $this.attr('href', 'mailto:' + mail + (subject.length ? '?subject=' + subject : ''));
        var text = jQuery.trim($this.text());
        // remove get parameters to have a clean email
        if ( text == "" || text == "::email::" ) {
            var quotation_mark_index = mail.indexOf('?');
            if (quotation_mark_index != -1) {
                mail = mail.substring(0, quotation_mark_index);
            }
            $this.html(mail);
        }

    });
    /*
    <a rel="antijunk_email" id="sebastien" class="imarcom.net" title="sujet ici">Martin Arpin</a>
    <a rel="antijunk_email" id="sebastien" class="imarcom.net" title="sujet ici">&nbsp;</a>
    */
}

function scrollToElement(element, options) {
    var options = $.extend({
        offset: 0
    }, options);

    var offset = $(element).offset();
    scrollToPosition(offset.top + parseInt(options.offset), options);
}

function scrollToPosition(top, options){
    // backward compatibility
    if (typeof arguments[1] == "number") {
        options = {speed: arguments[1]};
        if (typeof arguments[2] == "function") {
            options.callback = arguments[2];
        }
    }

    var options = $.extend({
        speed: 800,
        easing: "easeInOut",
        callback: function(){}
    }, options);

    var $a = $('body, html').stop().animate({
        scrollTop: top
    }, {
        duration: options.speed,
        easing: options.easing
    });
    $a.promise().done(options.callback);

    $('body, html').on('mousewheel.stop', function(event) {
        // clearInterval(interval);
        $('body, html').stop();
        $('body, html').off('mousewheel.stop');
    });

    $('body, html').promise().done(function() {
        // clearInterval(interval);
        $('body, html').off('mousewheel.stop');
    });
}

function getScrollTop(){
    if (typeof pageYOffset != 'undefined') {
        //most browsers except IE before #9
        return pageYOffset;
    } else {
        var B = document.body; //IE 'quirks'
        var D = document.documentElement; //IE with doctype
        D = (D.clientHeight)? D: B;
        return D.scrollTop;
    }
}

function __(key) {
    if (typeof locale[key] != "undefined") {
        return locale[key].replace('{CLEAN_BASE_SITE_URL}', '/');
    }

    return '{' + key + '}';
}

function getViewportWidth() {
    var width = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);

    // Fix because IE8 doesn't support media queries
    if (isIe8LESS && width < 1024) width = 1024;

    return width
}

/**
 * Exemple:
 * Ex�cuter un script par interval de 100ms sur le resize du window.

    doEventPeriodically($(window), 'resize', 100, function(){
        $(selecteur_jquery).trigger(mon_event);
    });

 */
function doEventPeriodically($target, _event, interval, callback) {
    var timeout;
    var timestamp = false;

    $target.on(_event, function(){
        clearTimeout(timeout);

        if (timestamp === false) {
            timestamp = Date.now();
        }

        if (Date.now() - timestamp >= interval) {
            _function();
            return;
        }

        timeout = window.setTimeout(_function, interval);
    });

    function _function() {
        timestamp = false;
        callback();
    }
}

function unknownError() {
    errorModal(__('error_append'), '<p>'+ __('error_unknown_append') + __('error_try_again_later') +' </p>');
}
