$( document ).ready(function() {

    if($('body#page_920630f3eba3dfd9a3bf4bc46ee86a6d').length) {
        var job = getQueryVariable('job');
        if(job) {
            job = job.charAt(0).toUpperCase() + job.slice(1);
            job = job.split(/-/i).join(' ');
            $("input[name='55d1dee1fef1b50bbbd9ea08f47ff672']").val(job);
        }
    }

    function getQueryVariable(variable)
    {
           var query = window.location.search.substring(1);
           var vars = query.split("&");
           for (var i=0;i<vars.length;i++) {
                   var pair = vars[i].split("=");
                   if(pair[0] == variable){return pair[1];}
           }
           return(false);
    }
});
