
function initVideo(id) {
    $("#" + id).on("click", ".js-video", function() {
        var youtube_id = $(this).data("youtube_id");
        
        $.fancybox({
            content: '<iframe class="video-fancybox" width="560" height="315" src="https://www.youtube.com/embed/'+youtube_id+'?rel=0&amp;showinfo=0&amp;autoplay=1" frameborder="0" allowfullscreen></iframe>',
            padding: 0,
            afterLoad: function() {
                $(".fancybox-overlay").addClass("fancybox-overlay-override");
            }
        });
    });
}
