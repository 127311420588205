
function initMainHero(id) {
    $("#" + id).on("click", ".js-mainHero-arrow", function() {
        var $mainHero = $(this).closest(".mainHero");

        scrollToElement($mainHero, {
            offset: $mainHero.height() - 30
        });
    });
}
