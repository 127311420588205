
var inspirationContentinitiated = false;

function initInspirationContent() {
    if (!inspirationContentinitiated) {
        inspirationContentinitiated = true;
        
        function resizeInspirationContentTitles() {
            var $titles = $(".js-inspirationContent-title");
            
            $titles.each(function() {
                var width = 0;
                
                $(this).css("width", "auto");
                
                $(this).find("span").each(function() {
                    var _width = $(this).position().left + $(this).width();
                    
                    if (_width > width) {
                        width = _width;
                    }
                });
                
                width += parseFloat($(this).css("padding-right"));
                
                $(this).css("width", width);
            });
        }
        
        resizeInspirationContentTitles();
        doEventPeriodically($(window), "resize", 200, resizeInspirationContentTitles);
    }
}
