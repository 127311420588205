(function ($) {
    /**
    * To initialize
    * $('#search').imarcomSearch({
    *       $elements: $('#container').children(),
    *       fields: {...},
    *       ...
    * })
    *
    * To trigger a filter
    * $('#search').imarcomSearch('filter')
    *
    * To trigger a sort
    * $('#search').imarcomSearch('sort', ['fieldname', +-1])
    *
    * Disable/Enable filtering, usefull for batch modification of fields like reset
    * $('#search').imarcomSearch('filter-disable');
    * $('#search').imarcomSearch('filter-enable');
    */
    $.fn.imarcomSearch = function( options_or_command, command_params ) {
        if ( typeof options_or_command == "string" ) {
            return this.trigger(options_or_command + '.imc-js-search', command_params);
        }

        var settings = $.extend({
            /**
            * List of filterable and sortable elements
            */
            $elements: $([]),

            /**
            * List of fields
            * {
            *   'category': {
            *       filterType: 'options',
            *   },
            *   'price': {
            *       filterType: 'range',
            *       sortable:true
            *   },
            *   'search': {
            *       filterType: 'text'
            *   },
            *   'new': {
            *       sortable:true
            *   }
            *}
            */
            fields: [],

            /**
            * This function should to hide or disable options that will lead to no results.
            * It takes one parameter, an object decribing the possible outcomes. For example :
            * {
            *   'category': {
            *       'cat1': false, // no results
            *       'cat2': true, // results
            *   },
            *   'price': {
            *       min:0,
            *       max:100,
            *   }
            * }
            */
            restrictFilters: false,

            /**
            * This function should return the current filters selected. For example,
            * {
            *   'category': ['cat1']
            *   'price': {
            *       min:0,
            *       max:100,
            *   }
            * }
            */
            getFilters: function () {},

            /**
            * This function is called after filter function,
            */
            afterFilter: function (settings) {},
            sort: function sort(key, direction) {
                function compare(a, b) {
                    if (a[key] < b[key]) {
                        return 1 * direction;
                    }
                    if (a[key] > b[key]) {
                        return -1 * direction;
                    }
                    if (a.index < b.index) {
                        return 1;
                    }
                    if (a.index > b.index) {
                        return -1;
                    }
                    return 0;
                }
                sort_array.sort(compare);
                $container.detach();
                for ( var i = 0 ; i < sort_array.length ; i ++ ) {
                    $container.append(sort_array[i].element);
                }
                $container_parent.append($container);

            },

            /**
            * This function is called after sort function,
            */
            afterSort: function (settings) {}
        }, options_or_command );

        var sortableFields = [];
        var filterableFields = [];
        var $container = settings.$elements.eq(0).parent(); // Elements parent node used to sort
        var $container_parent = $container.parent(); // Used to detach and reattach container
        var sort_array = []; // Used to sort efficiently
        var filter_enabled = true;
        var that = this;

        var keywords = '';

        /**
        * Execute once at startup
        */
        function init() {

            // Build sortableFields and filterableFields, handles default values on fields.
            var fieldname, config, fieldList = [];

            for ( fieldname in settings.fields ) {
                fieldList.push(fieldname);
                config = settings.fields[fieldname]; // pointer

                // sortable or not
                if ( typeof config.sortable != 'undefined' && config.sortable ) {
                    sortableFields.push(fieldname);
                } else {
                    settings.fields[fieldname].sortable = false;
                }

                // filter type
                if ( typeof config.filterType != 'undefined' && config.filterType ) {
                    filterableFields.push(fieldname);
                    if ( config.filterType == 'options' || config.filterType == 'keyword' ) {
                        settings.fields[fieldname].values = {};
                    }
                }
                else {
                    settings.fields[fieldname].filterType = false;
                }
			};

            fieldList = $.unique(fieldList);

            // Calculate the unique values of options filters and the base min and max of range filters
            // Build sort_array to sort efficiently
            var unique_ids = {};

            settings.$elements.each(function (index) {
                var $this = $(this),
                    id = $this.attr('id'),
                    field,
                    value,
                    element = {
                        element: this,
                        index: index
                    };

            	var keyword_attribute = $this.data('search');
            	if (keyword_attribute != undefined) {
            		keywords  = keywords + ' ' + keyword_attribute;
            	}

                $.each(fieldList, function(){

                    field = this.toString();
                    value = $this.data(field);


                    if (typeof unique_ids[field] == "undefined") {
                        unique_ids[field] = {};
                    }
                    if (unique_ids[field][id] === true) return;

                    if (settings.fields[field].sortable) {
                        element[field] = value;
                    }

                    if (settings.fields[field].filterType == 'range') {
                        if ( typeof settings.fields[field].min == 'undefined') {
                            settings.fields[field].min = value;
                            settings.fields[field].max = value;
                        } else {
                            if ( value < settings.fields[field].min ) {
                                settings.fields[field].min = value;
                            }
                            if ( value > settings.fields[field].max ) {
                                settings.fields[field].max = value;
                            }
                        }
                    }
                    else if (settings.fields[field].filterType == 'text') {
                        settings.fields[field].value = value;

                    }
                    else if (settings.fields[field].filterType == 'options' || settings.fields[field].filterType == 'keyword' ) {

                        if ( typeof value == 'string' && value.length > 0 ) {
                            var values = value.split(' ');

                            for ( var j = 0 ; j < values.length ; j ++ ) {
                                // use object key to prevent too many results
                                // assuming that an item may apear more than once
                                if ( typeof settings.fields[field].values[values[j]] == 'undefined' ) {
                                    settings.fields[field].values[values[j]] = {};
                                }
                                settings.fields[field].values[values[j]].id = true;

                                $this.addClass(field+'_'+_toUrl(values[j]));

                            }
                        } else {
                            // use object key to prevent too many results
                            // assuming that an item may apear more than once
                            if ( typeof settings.fields[field].values[value] == 'undefined' ) {
                                settings.fields[field].values[value] = {};
                            }
                            settings.fields[field].values[value][id] = true;

                            $this.addClass(field+'_'+value);
                        }
                    }
                });

                sort_array.push(element);
            });

            // build keyword list
            keywords = keywords.split(' ');
            var filtered_keywords = [];
            var known_keys = {};
            for ( var i=0; i<keywords.length; i++ ) {
            	if ( keywords[i] != '' ) {
            		if (known_keys[keywords[i]] !== true) {
            			known_keys[keywords[i]] = true;
	            		filtered_keywords.push({
	            			"id": _toUrl(keywords[i]),
	            			"text":  keywords[i].replace(/\_/g, ' ', keywords[i]).trim()
	            		});
            		}
            	}
            }

            // convert array key to count
            $.each(settings.fields, function(){
                var that = this;
				if ( this.filterType != 'keyword' ) {
	                $.each(this.values, function(key){
	                    that.values[key] = objSize(this);
	                });
	            }
	            else{
					if ( filtered_keywords.length ) {
						var $select2 = $('#filter .search input');
						$select2.on('toggle_check', function() {
							$select2[0].checked = $select2.val() != '';
						}).on('uncheck', function() {
							$select2[0].checked = false;
							$select2.select2('val', '');
						}).select2({
							placeholder: settings.locale.keyword_placeholder,
							allowClear: true,
							minimumInputLength: 2,
							data: filtered_keywords,
							formatInputTooShort: function () {
				                return settings.locale.keyword_2_more_chars;
				            },
							formatNoMatches: function(term) {
								return '<b>' + settings.locale.keyword_no_matches + '</b>';
							}
						});
                        $(".search .select2-container").css("display", "inline-block");

						if ($select2.val() !== '') {
							$select2.trigger('change'); // triggering change events
						}
					}
	            }
            });
        }

        function _toUrl(value) {

            value = value.toLowerCase();

            value = value.replace(/[àâ]/g,"a");
            value = value.replace(/[éèê]/g,"e");
            value = value.replace(/[ï]/g,"i");
            value = value.replace(/[ô]/g,"o");
            value = value.replace(/[û]/g,"u");

            value = value.replace(/[^a-z0-9]/g,"_");
            value = value.replace(/[\_]+/,"_");

            return value;
        }

        function objSize(obj) {
            var count = 0;

            if (typeof obj == "object") {

                if (Object.keys) {
                    count = Object.keys(obj).length;
                } else if (window._) {
                    count = _.keys(obj).length;
                } else if (window.$) {
                    count = $.map(obj, function() { return 1; }).length;
                } else {
                    for (var key in obj) if (obj.hasOwnProperty(key)) count++;
                }

            }

            return count;
        }
        /**
        * Sorts the $elements
        */
        function sort(key, direction) {
            settings.sort.call(that, key, direction);

            if ( typeof settings.afterSort == 'function' ) {
                settings.afterSort.call(that, settings);
            }
        }

        /**
        * Returns the results set of a filter
        */
        function getResults(selected_filters) {
            var $results = settings.$elements, field;

            for ( field in selected_filters ) {

                if ( settings.fields[field].filterType == 'options' || settings.fields[field].filterType == 'keyword' ) {
                    var selectors = [];

                    var values = selected_filters[field];

                    for ( var i = 0 ; i < values.length ; i ++ ) {
                        selectors.push('.' + field + '_' + values[i]);
                    }
                    $results = $results.filter(selectors.join(','));

                } else if ( settings.fields[field].filterType == 'range' ) {
                    $results = $results.filter(function( index ) {
                        var value = $(this).data(field);
                        return (
                            (typeof selected_filters[field].min == 'undefined' || value >= selected_filters[field].min) &&
                            (typeof selected_filters[field].max == 'undefined' || value <= selected_filters[field].max)
                        );
                    });
                } else if ( settings.fields[field].filterType == 'text' ) {
                    $results = $results.filter(function () {
                        return selected_filters[field].indexOf($(this).getAttr('id')) !== -1;
                    });
                }
            }
            return $results;
        }

        /**
        * Filters the $elements
        */
        function filter() {
            var selected_filters = settings.getFilters();
            var $result = getResults(selected_filters);
            settings.$elements.removeClass('filtered');
            $result.addClass('filtered');

            if ( typeof settings.restrictFilters == 'function' ) {
                settings.restrictFilters(computeFiltersStatus(selected_filters, $result));
            }

            if ( typeof settings.afterFilter == 'function' ) {
                settings.afterFilter.call(that, settings);
            }
        }

        /**
        * Calculate which values returns no results for all fields
        */
        function computeFiltersStatus(selected_filters, $result) {
            var filters_status = {};

            for ( var i = 0 ; i < filterableFields.length ; i ++ ) {
                var field = filterableFields[i];
                if ( typeof selected_filters[field] == 'undefined' ) {
                    // No current filters for this column, use result set
                    filters_status[field] = getPossibleOptions(field, $result);
                }
                else {
                    // There's a current filter for this column, exclude it
                    var test_filters = jQuery.extend(true, {}, selected_filters);
                    delete test_filters[field];
                    filters_status[field] = getPossibleOptions(field, getResults(test_filters));
                }
            }

            return filters_status;
        }

        /**
        * Calculate which values returns no results for a specific field
        */
        function getPossibleOptions(field, $result_set) {
            if ( settings.fields[field].filterType == 'options' ) {
                if ( $result_set.length == settings.$elements.length ) {
                    // Full set, return all possible values
                    return settings.fields[field].values;
                }
                // Calculating possible values
                var possible_values = {}, found_elements;
                for ( var value in settings.fields[field].values ) {
                    // calculate count of unique items - assuming that an item may apear more than once
                    found_elements = [];
                    $result_set.filter('.'+field+'_'+value).each(function(index){
                        found_elements.push($(this).attr('id') ? $(this).attr('id') : index);
                    });
                    found_elements = $.unique(found_elements);

                    possible_values[value] = $.unique(found_elements).length;

                    // possible_values[value] = $result_set.filter('.'+field+'_'+value).length;
                }
                return possible_values;
            } else if ( settings.fields[field].filterType == 'range' ) {
                if ( $result_set.length == settings.$elements.length ) {
                    // Full set, return all possible values
                    return {
                        min: settings.fields[field].min,
                        max: settings.fields[field].max
                    };
                }
                var min = settings.fields[field].max;
                var max = settings.fields[field].min;
                $result_set.each(function () {
                    var value = $(this).data(field);
                    if ( value < min ) {
                        min = value;
                    }
                    if ( value > max ) {
                        max = value;
                    }
                });
                return {
                    min: min,
                    max: max
                };
            } else if ( settings.fields[field].filterType == 'text' ) {
                return '';
            }
        }

        /**
        * Startup
        */
        init();
        if ( typeof settings.restrictFilters == 'function' ) {
            settings.restrictFilters(computeFiltersStatus({}, settings.$elements), true);
        }

        /**
        * Binding event to be able to trigger filter and sort.
        */
        this
            .bind('filter.imc-js-search', function () {
                if ( filter_enabled ) {
                    filter();
                }
            })
            .bind('sort.imc-js-search', function (e, field, direction) {
                sort(field, direction)
            })
            .bind('filter-enable.imc-js-search', function (e) {
                filter_enabled = true;
            })
            .bind('filter-disable.imc-js-search', function (e) {
                filter_enabled = false;
            })
            .bind('destroy.imc-js-search', function (e) {
                settings.$elements.removeClass('filtered hidden');
                delete settings;
                $(this).unbind('.imc-js-search');
            });

        this.data('api', {
            getFilters: settings.getFilters,
            getFields: function(){
                return settings.fields;
            }
        });

        return this;
    };
})(jQuery);
