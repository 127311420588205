
function initDealers(options) {
    var $map = $("#dealersGmap").imarcomGmap(options);
    var API = $map.data("API");
    
    $(".js-dealers-city").on("click", function() {
        if (!$(this).hasClass("dealers-filter-disabled")) {
            filterDealers($(this), 'city');
        }
    });
    $(".js-dealers-type").on("click", function() {
        if (!$(this).hasClass("dealers-filter-disabled")) {
            filterDealers($(this), 'type');
        }
    });
    
    function filterDealers($button, filter_name) {
        $button.toggleClass("dealers-filter-active");
        
        var json = {};
        
        json[filter_name] = $button.data(filter_name);
        
        var filters = API.updateFilters(json);
        
        if (Object.keys(filters).length < 1) {
            // No filters defined yet
            $(".js-dealerList").removeClass("dealerList-filtered");
            $(".js-dealerList-element").removeClass("dealerList-element-hide");
        } else {
            $(".js-dealerList").addClass("dealerList-filtered");
            
            $(".js-dealerList-element").each(function() {
                var $marker = $(this);
                var show = false;
                
                $.each(filters, function(key, values) {
                    $.map(values, function(value) {
                        var data = $marker.data(key);
                        datas = data.split(" ");
                        for (var i = 0; i < datas.length; i++) {
                            if (datas[i] == value) {
                                show = true;
                            }
                        }
                    });
                });
                
                if (show) {
                    $marker.removeClass("dealerList-element-hide");
                } else {
                    $marker.addClass("dealerList-element-hide");
                }
            });
        }
        
        disableUselessFilters(".js-dealers-city", "city");
        disableUselessFilters(".js-dealers-type", "type");
        
        function disableUselessFilters(selector, filter_name) {
            $(selector).each(function() {
                var $button = $(this);
                var enable = false;
                
                $(".js-dealerList-element:not(.dealerList-element-hide)").each(function() {
                    var $dealer_card = $(this);
                    var dealer_cities = $dealer_card.data("city");
                    var dealer_types = $dealer_card.data("type");
                    dealer_cities = dealer_cities.split(" ");
                    dealer_types = dealer_types.split(" ");
                    
                    if (filter_name == "city") {
                        var filter = $button.data("city");
                        
                        if ($(".js-dealers-type.dealers-filter-active").length) {
                            $(".js-dealers-type.dealers-filter-active").each(function() {
                                var type_filter = $(this).data("type");
                                for (var i = 0; i < dealer_types.length; i++) {
                                    if (dealer_types[i] == type_filter) {
                                        for (var j = 0; j < dealer_cities.length; j++) {
                                            if (dealer_cities[j] == filter) {
                                                enable = true;
                                            }
                                        }
                                    }
                                }
                            });
                        } else {
                            enable = true;
                        }
                    }
                    else if (filter_name == "type") {
                        var filter = $button.data("type");
                        
                        if ($(".js-dealers-city.dealers-filter-active").length) {
                            $(".js-dealers-city.dealers-filter-active").each(function() {
                                var city_filter = $(this).data("city");
                                for (var i = 0; i < dealer_cities.length; i++) {
                                    if (dealer_cities[i] == city_filter) {
                                        for (var j = 0; j < dealer_types.length; j++) {
                                            if (dealer_types[j] == filter) {
                                                enable = true;
                                            }
                                        }
                                    }
                                }
                            });
                        } else {
                            enable = true;
                        }
                    }
                });
                
                if (enable) {
                    $button.removeClass("dealers-filter-disabled");
                } else {
                    $button.addClass("dealers-filter-disabled");
                }
            });
        }
    }
}
