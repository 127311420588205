/**
 * 2016-05-06 - Jean-François Sanfaçon <jfsanfacon@imarcom.net>
 *
 * Dependencies :
 * 		OpenModal locales
 */
;(function($, undefined) {
	"use strict";

	function makeid(charlength) {
		var charlength = charlength || 10;
		var text = "";
		var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

		for( var i=0; i < 10; i++ ) {
			text += possible.charAt(Math.floor(Math.random() * possible.length));
		}

		return text;
	}

	$.openModal = function(opts) {
		// We allow to set empty array to have no buttons at all
		if ( opts.buttons != undefined && !opts.buttons.length ) {
			opts.buttons = {};
		}

		var options = $.extend(true, {}, $.openModal.defaultOption, opts);
		var modal_key = options.id == false ? 'modal_' + makeid() : options.id;
		var $modal = $('#' + modal_key);

		if ( $modal.length ) {
			$modal.off('hidden.bs.modal').on('hidden.bs.modal', queueModal).modal('hide');
		} else {
			$modal = $([
				'<div class="bootstrap-dialog modal type-' + options.type.replace('error', 'danger') + ' fade '+ options.classes +'"'+ (options.hideOnOverlayClick ? '' : ' data-backdrop="static"') +' id="'+ modal_key +'" role="dialog" aria-labelledby="'+ modal_key +'Label" aria-describedby="'+ modal_key +'Content" aria-hidden="true">',
					'<div class="modal-dialog modal-'+ options.modal_size +'">',
						'<div class="modal-content">',
							(
								options.title ? [
									'<div class="modal-header">',
										'<button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>',
										'<h4 class="modal-title" id="' + modal_key + 'Label"></h4>',
									'</div>'
								].join('') : ''
							),
							'<div class="modal-body clearfix" id="' + modal_key + 'Content"></div>',
							(options.buttons.length ? '<div class="modal-footer"></div>' : ''), // '<div class="modal-footer"><button type="button" class="btn btn-default" data-dismiss="modal">'+ $.openModal.locale.close_label +'</button></div>'),
						'</div>',
					'</div>',
				'</div>'
			].join(''))
			.on('shown.bs.modal', function (e) {
				// 2016-05-06 jfs - We focus on the first element than we may be interested
				$(this).find('.modal-body, .modal-footer').find(options.focus).eq(0).focus();
			})
			.on('hidden.bs.modal', destroyModal)
			.modal(options.bootstrap_modal_config);

			$.each(options.buttons, function (button) {
				this.type = (this.type ? this.type.replace('error', 'danger') : 'default');
				this.classes = this.classes || '';
				var $btn = $('<button type="button" class="btn btn-'+ this.type +' '+ this.classes +'"'+ (this.dismiss ? ' data-dismiss="modal"' : '') +'>' + this.label + '</button>');

				if ( typeof this.callback == 'function' ) {
					$btn.on('click', $modal, this.callback);
				}

				$modal.find('.modal-footer').append($btn);
			});

			// var $body = $('.modal-body', $modal).empty();
			// var $title = $('.modal-title', $modal).empty();

			// if ( options.content.jquery != undefined ) {
			// 	options.content.wrap('<span class="placeholder"></span>');
			// 	options.content.data('placeholder', options.content.parent());
			// 	$body.data('dynamic_content', options.content);
			// }

			// We allow anonymous function to set content
			$('.modal-body', $modal).append(options.content);
			$('.modal-title', $modal).append(options.title);
		}

		function replaceDynamicContent($modal) {
			var $c = $modal.data('dynamic_content');
			$dynamic_content.appendTo($dynamic_content.data('placeholder'));
			$dynamic_content.unwrap();
			$dynamic_content.removeData('placeholder');
		}

		function queueModal() {
			// console.log('queueModal', $(this));
			// replaceDynamicContent($modal);

			$('.modal-title', $modal).append(options.title);
			$('.modal-body', $modal).append(options.content);
			$modal.modal('show');
			$modal.off('hidden.bs.modal').on('hidden.bs.modal', destroyModal);
		}

		function destroyModal() {
			$(this).remove();

			if ( typeof options.onClose == 'function' ) {
				options.onClose();
			}

			// 2016-05-06 jfs + andrei - Work-around to allow scrolling when multiple modal is open
			if ( ($('.modal').data('bs.modal') || {}).isShown ) {
				$('body').addClass('modal-open');
			}
		}

		return $modal;

	};

	$.openModal.locale = {
		close_label: 'Close',
		error_happend: 'An error has occurred.',
		error_unknow_happend: 'An unknown error occured.',
		error_try_again_later: 'Try again later.'
	};

	$.openModal.defaultOption = {
		type: 'default', // [default,error,info,primary,success,warning,danger]
		title: '',
		content: '',
		classes: '',
		id: false, // only one instance of same id can be open at same time
		// close_label: 'Close',
		modal_size: 'sm', // sm | md | lg
		show: true,
		focus: ':input, .btn-primary, .btn-default',
		hideOnOverlayClick: true,
		bootstrap_modal_config: {},
		buttons: [
			{ type: 'default', dismiss: true, label: $.openModal.locale.close_label }
			 // {type: 'danger', label:'label', dismiss: false, callback: function() { console.log('button callback'); }},
		],
		onClose: false
	};

	// cheap shot for "auto-open: false" option
	/*
	$.createModal = function(opts) {
		var options = $.extend(true, {}, defaultOption, opts);
		var modal_key = options.id == false ? 'modal_' + makeid() : options.id;
		var $modal = $('#' + modal_key);

		function buildModal(){
			return $([
				'<div class="bootstrap-dialog modal type-' + options.type.replace('error', 'danger') + ' fade" id="' + modal_key + '" tabindex="-1" role="dialog" aria-labelledby="' + modal_key + 'Label" aria-describedby="' + modal_key + 'Content" aria-hidden="true">',
					'<div class="modal-dialog modal-'+options.modal_size+'">',
						'<div class="modal-content">',
							'<div class="modal-header">',
								'<button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>',
								'<h4 class="modal-title" id="' + modal_key + 'Label"></h4>',
							'</div>',
							'<div class="modal-body clearfix" id="' + modal_key + 'Content"></div>',
							(options.buttons.length ? '<div class="modal-footer"></div>' : ''),
						'</div>',
					'</div>',
				'</div>'
			].join('')).modal(options.bootstrap_modal_config);
		}

		$modal = buildModal();
		$.each(options.buttons, function (button) {
			$modal.find('.modal-footer').append($('<button type="button" class="btn btn-'+this.type+'">' + this.label + '</button>').bind('click', this.callback));
		});
		var $body = $('.modal-body', $modal).empty();
		var $title = $('.modal-title', $modal).empty();

		if( options.content.jquery != undefined ) {
			options.content.wrap('<span class="placeholder"></span>');
			options.content.data('placeholder', options.content.parent());
			$body.data('dynamic_content', options.content);
		}

		$body.append(options.content);
		$title.append(options.title);

		return $modal;
	};
	*/

})(jQuery);

function errorModal(title, content, config, onClose) {
	config = config || {};
	config.title = title || $.openModal.locale.error_title;
	config.content = content || $.openModal.locale.error_happend;
	config.onClose = onClose;
	config.type = 'danger';
	return $.openModal(config);
}

// We make an alias for errorModal
var dangerModal = errorModal;

function infoModal(title, content, config, onClose) {
	config = config || {};
	config.title = title || '&nbsp;';
	config.content = content;
	config.onClose = onClose;
	config.type = 'info';
	return $.openModal(config);
}

function primaryModal(title, content, config, onClose) {
	config = config || {};
	config.title = title || '&nbsp;';
	config.content = content;
	config.onClose = onClose;
	config.type = 'primary';
	return $.openModal(config);
}

function successModal(title, content, config, onClose) {
	config = config || {};
	config.title = title || '&nbsp;';
	config.content = content;
	config.onClose = onClose;
	config.type = 'success';
	return $.openModal(config);
}

function warningModal(title, content, config, onClose) {
	config = config || {};
	config.title = title || '&nbsp;';
	config.content = content;
	config.onClose = onClose;
	config.type = 'warning';
	return $.openModal(config);
}

function unknownError() {
	return errorModal($.openModal.locale.error_happend, $.openModal.locale.error_unknow_happend +'<br>'+ $.openModal.locale.error_try_again_later);
}
