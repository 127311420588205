
// How to use imarcomGmap filters
// var $map = $("#imarcomGmap").imarcomGmap();
// var API = $map.data("API");
// API.updateFilters({
//     filter_name: filter_value
// });
// filter_name must match your markup: <div class="imarcomGmap-marker" data-filter_name="filter_value"></div>

(function($){
    
    $.fn.imarcomGmap = function(opts) {
        var $map = $(this);
        
        if ($map.length) {
            var $markersContainer = $("#imarcomGmap-markers");
            var $markers = $(".imarcomGmap-marker", $markersContainer);
            var markers = [];
            var filters = {};
            var infowindow = new google.maps.InfoWindow();
            var center = {
                lat: 46.816085, // Quebec
                lng: -71.218268 // Quebec
            };
            
            if ($markers.length) {
                var lat = parseFloat($markers.eq(0).data("lat").replace(",", "."));
                var lng = parseFloat($markers.eq(0).data("lng").replace(",", "."));
                
                if (lat && lng) {
                    center = {
                        lat: lat,
                        lng: lng
                    }
                }
            }
            
            var map = new google.maps.Map($map[0], {
                center: center,
                zoom: 10,
                gestureHandling: "cooperative",
        styles: [
                  {
                    "elementType": "geometry",
                    "stylers": [
                      {
                        "color": "#f5f5f5"
                      }
                    ]
                  },
                  {
                    "elementType": "labels.icon",
                    "stylers": [
                      {
                        "visibility": "off"
                      }
                    ]
                  },
                  {
                    "elementType": "labels.text.fill",
                    "stylers": [
                      {
                        "color": "#616161"
                      }
                    ]
                  },
                  {
                    "elementType": "labels.text.stroke",
                    "stylers": [
                      {
                        "color": "#f5f5f5"
                      }
                    ]
                  },
                  {
                    "featureType": "administrative.land_parcel",
                    "elementType": "labels.text.fill",
                    "stylers": [
                      {
                        "color": "#bdbdbd"
                      }
                    ]
                  },
                  {
                    "featureType": "poi",
                    "elementType": "geometry",
                    "stylers": [
                      {
                        "color": "#eeeeee"
                      }
                    ]
                  },
                  {
                    "featureType": "poi",
                    "elementType": "labels.text.fill",
                    "stylers": [
                      {
                        "color": "#757575"
                      }
                    ]
                  },
                  {
                    "featureType": "poi.park",
                    "elementType": "geometry",
                    "stylers": [
                      {
                        "color": "#e5e5e5"
                      }
                    ]
                  },
                  {
                    "featureType": "poi.park",
                    "elementType": "labels.text.fill",
                    "stylers": [
                      {
                        "color": "#9e9e9e"
                      }
                    ]
                  },
                  {
                    "featureType": "road",
                    "elementType": "geometry",
                    "stylers": [
                      {
                        "color": "#ffffff"
                      }
                    ]
                  },
                  {
                    "featureType": "road.arterial",
                    "elementType": "labels.text.fill",
                    "stylers": [
                      {
                        "color": "#757575"
                      }
                    ]
                  },
                  {
                    "featureType": "road.highway",
                    "elementType": "geometry",
                    "stylers": [
                      {
                        "color": "#dadada"
                      }
                    ]
                  },
                  {
                    "featureType": "road.highway",
                    "elementType": "labels.text.fill",
                    "stylers": [
                      {
                        "color": "#616161"
                      }
                    ]
                  },
                  {
                    "featureType": "road.local",
                    "elementType": "labels.text.fill",
                    "stylers": [
                      {
                        "color": "#9e9e9e"
                      }
                    ]
                  },
                  {
                    "featureType": "transit.line",
                    "elementType": "geometry",
                    "stylers": [
                      {
                        "color": "#e5e5e5"
                      }
                    ]
                  },
                  {
                    "featureType": "transit.station",
                    "elementType": "geometry",
                    "stylers": [
                      {
                        "color": "#eeeeee"
                      }
                    ]
                  },
                  {
                    "featureType": "water",
                    "elementType": "geometry",
                    "stylers": [
                      {
                        "color": "#c9c9c9"
                      }
                    ]
                  },
                  {
                    "featureType": "water",
                    "elementType": "labels.text.fill",
                    "stylers": [
                      {
                        "color": "#9e9e9e"
                      }
                    ]
                  }
                ]
            });

            map.addListener("idle", function() {
                google.maps.event.clearListeners(map, "idle");
                refresh();
            });
            
            function refresh() {
                refreshMarkers();
                fit();
            }

            function refreshMarkers() {
                clearMarkers();
                
                $markers.each(function() {
                    var $marker = $(this);
                    
                    if (filter($marker)) {
                        var lat = parseFloat($(this).data("lat").replace(",", "."));
                        var lng = parseFloat($(this).data("lng").replace(",", "."));
                        
                        if (lat && lng) {
                            var latlng = {
                                lat: lat,
                                lng: lng
                            };
                            
                            if (opts && opts.marker) {
                                var icon = opts.marker;
                                
                            } else {
                                if ($markers.length > 1 && $.isEmptyObject(filters)) {
                                    var icon = common_dir + "/images/pin-gray.svg";
                                    
                                    if (opts && opts.gray_marker) {
                                        icon = opts.gray_marker;
                                    }
                                } else {
                                    var icon = common_dir + "/images/pin-orange.svg";
                                    
                                    if (opts && opts.orange_marker) {
                                        icon = opts.orange_marker;
                                    }
                                }
                            }
                            
                            var marker = new google.maps.Marker({
                                position: latlng,
                                map: map,
                                icon: icon
                            })
                            
                            marker.$element = $(this);
                            markers.push(marker);
                            
                            addInfoWindow(marker);
                        }
                    }
                });
            }

            function clearMarkers() {
                for (var i = 0; i < markers.length; i ++) {
                    markers[i].setMap(null);
                }
                markers = [];
            }
            
            function filter($marker) {
                if (Object.keys(filters).length < 1) {
                    // No filters defined yet
                    return true;
                }
                
                var shows = [];
                var index = 0;
                $.each(filters, function(key, values) {
                    shows[index] = false;
                    $.map(values, function(value) {
                        if ($marker.data(key) == value || $marker.data(key) == 'always') {
                            shows[index] = true;
                        }
                    });
                    index++;
                });
                
                var show = true;
                $.each(shows, function(key, value) {
                    if (value === false) {
                        show = false;
                    }
                });
                return show;
            }
            
            function addInfoWindow(marker) {
                marker.addListener('click', function() {
                    infowindow.setContent(marker.$element.text());
                    infowindow.open(open, marker);
                });
            }
            
            function fit() {
                var bounds = getMarkerBounds();
                
                // Don't zoom in too far on only one marker
                if (bounds.getNorthEast().equals(bounds.getSouthWest())) {
                    var extendPoint1 = new google.maps.LatLng(bounds.getNorthEast().lat() + 0.01, bounds.getNorthEast().lng() + 0.01);
                    var extendPoint2 = new google.maps.LatLng(bounds.getNorthEast().lat() - 0.01, bounds.getNorthEast().lng() - 0.01);
                    bounds.extend(extendPoint1);
                    bounds.extend(extendPoint2);
                }
                
                map.fitBounds(bounds);
            }
            
            function getMarkerBounds() {
                var bounds = new google.maps.LatLngBounds();
                
                $.map(markers, function(marker) {
                    bounds.extend(marker.getPosition());
                });
                
                return bounds;
            }
            
            function updateFilters(config) {
                $.each(config, function(key, value) {
                    if (typeof(filters[key]) == "undefined") {
                        filters[key] = [];
                    }
                    
                    if (filters[key].indexOf(value) != -1) {
                        // Already in array, remove
                        var index = filters[key].indexOf(value);
                        filters[key].splice(index, 1);
                        
                        if (filters[key].length < 1) {
                            delete filters[key];
                        }
                    } else {
                        // Not in array, add
                        filters[key].push(value);
                    }
                });
                
                refresh();
                
                return filters;
            }
        }
        
        $map.data("API", {
            updateFilters: updateFilters
        });
        
        return this;
    };
    
})(jQuery);
