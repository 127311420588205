
function initZoomableInspirations() {
    $(".js-inspirationMosaic-element").on("click", function() {
        var image_src = $(this).data("src");
        var image = new Image();
        image.src = image_src;

        image.onload = function() {
            $.fancybox({
                content: '<img class="zoomedProduct" src="'+image_src+'" alt="" />',
                padding: 0,
                closeBtn: false,
                afterLoad: function() {
                    $(".fancybox-outer").append('<a class="zoomedProduct-close icon-close" href="javascript:;"></a>');
                }
            });
        }
    });

    $("body").on("click", ".zoomedProduct-close", function() {
        $.fancybox.close();
    });
}

function initInspirationMosaic() {
    $("#filters").initFilters({
        $elements: $(".inspirationMosaic-element"),
        fields: {
            "style": {
                filterType: "options"
            },
            "category": {
                filterType: "options"
            },
            "type": {
                filterType: "options"
            }
        },
        afterFilter: function() {
            $(".inspirationMosaic").addClass("inspirationMosaic-filtered");

            $(".inspirationMosaic-element").removeClass([
                "inspirationMosaic-element-1",
                "inspirationMosaic-element-2",
                "inspirationMosaic-element-3",
                "inspirationMosaic-element-4",
                "inspirationMosaic-element-5",
                "inspirationMosaic-element-6",
                "inspirationMosaic-element-7",
                "inspirationMosaic-element-8",
                "inspirationMosaic-element-9"
            ].join(" "));

            var index = 2;

            $(".inspirationMosaic-element").each(function() {
                if ($(this).hasClass("filtered")) {
                    $(this).addClass("inspirationMosaic-element-" + index);

                    // if (index == 6 || index == 9) {
                    //     var zoomed_image = $(this).data("src");
                    //     $(this).css("background-image", "url("+zoomed_image+")");
                    // }

                    index++;

                    if (index > 9) {
                        index = 1;
                    }
                }
            });

            if (
                ($("#filter-style-contemporain").hasClass("filterSelected") && !$("#filter-style-classique").hasClass("filterSelected")) ||
                ($("#filter-style-classique").hasClass("filterSelected") && !$("#filter-style-contemporain").hasClass("filterSelected"))
            ) {
                $(".inspirationMosaic").addClass("inspirationMosaic-hideStyles");
            } else {
                $(".inspirationMosaic").removeClass("inspirationMosaic-hideStyles");
            }
        }
    });
}
