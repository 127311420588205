
var FormHandler = {
    initForm: function($form, $buttons, postFunction, autocomplete) {
        autocomplete = autocomplete || false;
        $form.data('submit', postFunction);
        
        if(!Modernizr.input.placeholder){
            
            $('[placeholder]', $form).focus(function() {
                var input = $(this);
                if (input.val() == input.attr('placeholder')) {
                    input.val('');
                    input.removeClass('placeholder');
                }
            }).blur(function() {
                var input = $(this);
                if (input.val() == '' || input.val() == input.attr('placeholder')) {
                    input.addClass('placeholder');
                    input.val(input.attr('placeholder'));
                }
            }).blur();
            
            $('[placeholder]', $form).parents('form').submit(function() {
                $(this).find('[placeholder]').each(function() {
                    var input = $(this);
                    if (input.val() == input.attr('placeholder')) {
                        input.val('');
                    }
                });
            });
            
        }
        
        $form.bind('enable', function(){
            $form.removeAttr("onsubmit");
            this.onsubmit = $form.data('submit');
            $buttons.not('button[type=submit]').bind('click', function(e){
                e.preventDefault(); //fix button type submit double post
                $form.submit();
            });
        });
        
        $form.bind('disable', function(){
            $form.attr("onsubmit", 'return false;');
            this.onsubmit = function(){ return false; };
            $buttons.not('button[type=submit]').unbind('click');
        }).trigger('disable');
        
        
        window.setTimeout(function(){
            autocomplete && $form.removeAttr('autocomplete');
        }, 100);
    },
    initInputType: function($form){
        //PASSWORD INPUT
        $(".password :input[type=password]:not(.initialized)", $form).each(function(){
            $(this).addClass('initialized').simplePassMeter({
                'showOnFocus': true,
                'requirements': {},
                'container': $(this).parent(),
                'location': 't',
                'defaultText': __('password_meter'),
                'ratings': [
                    {'minScore': 0,
                    'className': 'meterFail',
                    'text': __('password_level_0')
                    },
                    {'minScore': 25, 
                    'className': 'meterWarn',
                    'text': __('password_level_2')
                    },
                    {'minScore': 50, 
                    'className': 'meterGood',
                    'text': __('password_level_4')
                    },
                    {'minScore': 75, 
                    'className': 'meterExcel',
                    'text': __('password_level_5')
                    }
                ]
            });
        });

        //OTHER INPUTS
        $('.field-other', $form).each(function(){
            var $other_container = $(this);

            if( $other_container.is('.other-select') ) {
                var $select = $other_container.closest('.form-group').find("select");

                $select.change(function(){
                    if( $(this).val()=='!other!' ) {
                        $other_container.addClass('visible').find(':input').focus().select();
                    } else {
                        $other_container.removeClass('visible');
                    }
                }).select(function(){$(this).blur();}).trigger("change");
                
            } else if ( $other_container.is('.other-radio') ) {
                var $inputs = $other_container.closest('.form-group').find("[type='radio']");
                var $other_radio = $inputs.filter('.other');
                
                $inputs.change(function(){
                    window.setTimeout(function(){
                        if( $other_radio.prop('checked') ) {
                            $other_container.addClass('visible').find(':input').focus().select();
                        } else {
                            $other_container.removeClass('visible');
                        }
                    },1);
                    return true;
                }).trigger("change");

            } else if ( $other_container.is('.other-check') ) {
                var $input = $("[type='checkbox'].other", $other_container);
                
                $input.change(function(){
                    if( $(this).prop('checked') ) {
                        $other_container.addClass('visible').find(':input').focus().select();
                    } else {
                        $other_container.removeClass('visible');
                    }
                }).trigger("change");
            }
        });

        //AUTOTAB
        $(".autotab_group", $form).each(function(){
            $(this).find('input').autotab();
        });

        //DATE INPUT
        // if ( !Modernizr.inputtypes.date ) {
        //     $(':input[type=date]', $form).datepicker();
        // }

        //PHONE INPUT
        $('.phone-input [type=tel]', $form).mask("(999) 999-9999", {
            clearIfIncomplete: false
        });
    },
    scrollToError: function($form, callback){
        callback = callback || function(){};
        var $first_error = $form.find('.has-error:first');
        var first_top = $first_error.offset()['top'] - scrollTopOffset;
        var min = Math.max(0, $form.offset().top - scrollTopOffset);
        var form_bottom = $form.height() + $form.offset().top + scrollTopOffset;
        var max = form_bottom - $(window).height();
        
        $first_error.find("input, textarea, select").eq(0).focus();
        
        // utilisation d'une fonction commune pour scroll (SEB) 2014-09-29
        scrollToPosition(Math.max(min, Math.min(max, first_top)), {
            speed: 800,
            callback: callback
        });
    },
    scrollToFormTop: function($form, callback){
        callback = callback || function(){};
        $('html, body').animate({
            scrollTop: Math.max(0, $form.offset()['top'] - 50)
        }, {
            duration: 600,
            complete: callback,
            easing: 'easeInOutCubic'
        });
    },
    onSuccess: function(mustReload){
        var fork = new String(this.getResponseHeader('x-fork-to'));
        FormHandler.forkTo(fork, mustReload);
    },
    forkTo: function(fork, mustReload){
        if ( fork != 'null' && fork != 'undefined' ) {
            //browser version check = new patch (2011-01-12)
            if( $.browser.msie && parseInt($.browser.version) <= 6 ) {
                var referLink = document.createElement('a');
                //referLink.href = (fork + window.location.hash);
                referLink.href = fork;
                document.body.appendChild(referLink);
                referLink.click();
            }
            else {
                //lastpatch (2011-01-12)
                window.location = (fork + (window.location.hash || ''));
                //window.location.reload(true);
            }
        }
        else if( typeof mustReload != 'undefined' && mustReload == true ) {
            window.location.reload(true);
        }
    },
    handleErrors: function(elements){
        var $form = $(elements.form);
        if( typeof $.evalJSON == 'undefined' ) {
            alert('JSON class not loaded!');
            return;
        }
        try{ var ajax_answer = $.evalJSON(this.responseText); }
        catch(e){ var ajax_answer = {errors:[__('error_unknown_append') + '<br />' + __('error_try_again_later')]} }
        
        if(typeof ajax_answer.errors == 'undefined') {
            $.extend(ajax_answer,{errors:[__('error_unknown_append') + '<br />' + __('error_try_again_later')]});
        }
        return FormHandler.displayErrors($form, ajax_answer.errors);
    },
    remove_qtip: function(){
        if( typeof $(this).data('QT').qtip('api') != 'undefined' ) {
            $(this).data('QT').qtip('api').destroy();
        }
    },
    inputOnFocus: function(){
        $(this).trigger('remove_qtip');
    },
    inputOnKeypress: function(){
        $(this).trigger('remove_error');
        FormHandler.clearErrors($(this).data('errored'));
    },
    displayErrors:function($form, _e) {
        var toModal = [], 
            e_elements = [], 
            $input, 
            chunks, 
            name, 
            filter, 
            $labels, 
            $form_group, 
            $errored, 
            $form_group_exeption, 
            $QT,
            xx;
        
        for( xx in _e ) {

            if( (typeof xx).toLowerCase() == 'string' ) {
                
                chunks = xx.replace(/::/g, '_!_').split(':');
                name = chunks[0].replace(/_!_/g, '::');
                filter = ':last';
                if( typeof chunks[1] != 'undefined' ) {
                    filter = ':eq(' + chunks[1] + ')';
                }
                
                // find INPUTs
                try {
                    $input = $form.find("[name='" + name + "']");

                    if( !$input.length ) {
                        $input = $form.find("[name^='" + name + "\[']");
                    }
                }
                catch(e){ $input = $([]); }
                
                // si pas trouvé input
                if( !$input.length ) continue;
                
                // find errored containers
                $errored = $([]);
                $form_group = $input.closest('.form-group');
                $form_subgroup = $input.closest('.form-subgroup');
                if( $form_subgroup.length ) {
                    $errored = $errored.add($form_subgroup.addClass('has-error'));
                } else {
                    $errored = $errored.add($form_group.addClass('has-error'));
                }
                $errored = $errored.add($form.find('.label_' + name.replace('[]', '')).addClass('has-error'));
                
                // bind methods
                (function($input, $errored){
                    $input.
                        data('errored', $errored).
                        unbind('.error').
                        bind('focus.error', FormHandler.inputOnFocus).
                        bind('keypress.error change.error', FormHandler.inputOnKeypress);
                })($input, $errored);
                
                e_elements.push({ name: _e[xx] });
                
                if( !_e[xx].length ) { // if no message, we leave after adding css error class
                    continue;
                }
                

                // qtip display
                $QT = $form_group.find('.qtipable:first');
                if( !$QT.length ) {
                    $QT = $form_group.filter('.qtipable');
                }
                    
                if( $QT.length ) {

                    $QT.data('error', _e[xx]);
                    
                    //qtip position
                    var qt_position = {
                        my: 'left center',
                        at: 'right center'
                    }; //east - default position
                    if( $QT.is('.qtip_n') ) qt_position = { my: 'bottom center', at: 'top center' }; //north
                    if( $QT.is('.qtip_s') ) qt_position = { my: 'top center', at: 'bottom center' }; //south
                    if( $QT.is('.qtip_w') ) qt_position = { my: 'right center', at: 'left center' }; //west
                    if( $QT.is('.qtip_ne') ) qt_position = { my: 'bottom left', at: 'top right' }; //north east
                    if( $QT.is('.qtip_se') ) qt_position = { my: 'top left', at: 'bottom right' }; //south east
                    if( $QT.is('.qtip_sw') ) qt_position = { my: 'top right', at: 'bottom left' }; //south west
                    if( $QT.is('.qtip_nw') ) qt_position = { my: 'bottom right', at: 'top left' }; //north west
                    
                    if( $QT.data('qtip_my') ) {
                        qt_position.my = $QT.data('qtip_my');
                    }
                    if( $QT.data('qtip_at') ) {
                        qt_position.at = $QT.data('qtip_at');
                    }
                    qt_position.viewport = $(window);
                    
                    $QT.data('QT', $QT).addClass('qtiped');
                    $input.data('QT', $QT);
                    
                    $QT.qtip({
                        //overwrite: false,
                        content: function(){
                            return $(this).data('error');
                        },
                        position: qt_position,
                        show: {
                            event: false,
                            ready: true
                        }, 
                        hide: false,
                        style: {
                            classes: 'qtip-red qtip-rounded ui-tooltip-shadow'
                        }
                    });
                    
                    $input.bind('remove_qtip.error', FormHandler.remove_qtip);
                    
                } else {
                    FormHandler.addError($input, _e[xx], name, true);
                }
            }
        }
        
        
        return e_elements;
    },
    addErrorMessageRow:function($tr_containing_the_field, message){
        var $clone_row = $tr_containing_the_field.clone();
        var $container = $clone_row.find('td.field');
        if( !$container.length ) {
            $container = $clone_row.find('td:first');
        }
        $container.addClass('error_message').empty().html('<div class="pad">' + message + '</div>');
        $clone_row.find('td').removeClass('field value');
        
        var cols = $.sum($.map($clone_row.find('td'), function(td){ return Math.max(1, $(td).attr('colspan')||1); }));
        $clone_row.find('td:not(.error_message)').remove();
        $container.attr('colspan', cols);
        var $error_row = $('<tr class="error_row"></tr>').html($clone_row.html());
        if( $clone_row.is('.e_before') ) {
            $error_row.insertBefore($tr_containing_the_field);
        } else {
            $error_row.insertAfter($tr_containing_the_field);
        }
        return $error_row;
    },
    addNotice: function($input, message, key, removeOnUserAction){ //work with bootstrap
        FormHandler._addMessage($input, message, 'warning', key, removeOnUserAction);
    },
    addError: function($input, message, key, removeOnUserAction){ //work with bootstrap
        FormHandler._addMessage($input, message, 'danger', key, removeOnUserAction);
    },
    _addMessage: function($input, message, type, key, removeOnUserAction){
        removeOnUserAction = removeOnUserAction || false;
        var key = key || $input.attr('name');
        var $form_group = $input.closest('.form-group');
        var $label = $form_group.find('> .control-label');
        var $first_col = $form_group.find('> div:first');
        var classes = [], result, colRegExp = /^col-([xsmdlg]{2})-(offset)?-?([0-9]{1,2})/;

        if( removeOnUserAction ) {
            var event_name = 'keypress';
            if( $input.is('select, [type=file]') ) event_name = 'change';
            if( $input.is('[type=radio], [type=checkbox]') ) event_name = 'click';

            $input.unbind('remove_error.error').bind('remove_error.error', function(){
                FormHandler.removeNotice($input, key);
                FormHandler.clearErrors($form_group);
                $(this).unbind('remove_error.error ' + event_name + '.error');
            });
            
            $input.unbind(event_name + '.error').bind(event_name + '.error', function(){
                $(this).trigger('remove_error').unbind(event_name + '.error');
            });
        }

        if( typeof $form_group.data(key) == 'undefined' ) {
            var $error_row = $('<div class="error"></div>').addClass('warning_' + key.split('[')[0]).bind('remove', function(){
                $form_group.removeData(key);
            });
            
            if( $label.length ) {
                classes = $.map($label.attr('class').split(' '), function(a){
                    var result = colRegExp.exec(a);
                    if( result!=null ) {
                        return 'col-' + result[1] + '-' + (12 - parseInt(result[3]));
                    }
                });

                classes.length && $('<label class="' + $label.attr('class') + ' hidden-xs">&nbsp;</label>').appendTo($error_row).removeClass('control-label');
            } else {
                if( $first_col.length ) {
                    attr_class = $first_col.attr('class');
                    if (typeof attr_class !== "undefined") {
                        classes = $.map(attr_class.split(' '), function(a){
                            var result = colRegExp.exec(a);
                            if (result != null) {
                                if( result[2]=='offset' ) {
                                    return result.input;
                                }
                            }
                        });
                    }
                }
                
                if (!classes.length && $label.length) {
                    classes = ['col-xs-12'];
                }
            }

            $('<div><div class="message alert alert-'+type+'">' + message + '</div></div>').addClass(classes.join(' ')).appendTo($error_row);
            $('<div class="clearfix"></div>').appendTo($error_row);

            $form_group.data(key, $error_row);
            $error_row.appendTo($form_group);
        }
    },
    removeNotice: function($input, key) { //work with bootstrap
        var key = key || $input.attr('name');
        var $form_group = $input.closest('.form-group');
        if( $input.is('[type=file]') ) {
            // TODO
        }
        
        if( typeof $form_group.data(key) != 'undefined' ) {
            $form_group.data(key).remove();
            $form_group.removeData(key);
        }
    },
    clearErrors:function(form_element) {
        var $elements = $([]),
            $element,
            $sub_groups;
        
        $(form_element).each(function(){
            $element = $(this);
            
            if( $element.hasClass('form-group') ) {
                $elements = $elements.add($element);
                $elements.removeData();
                return;
            }
            
            $sub_groups = $('.form-group', $element);
            if( $sub_groups.length ) {
                $elements = $elements.add($sub_groups);
                $sub_groups.removeData();
                return;
            }
            
            $elements = $elements.add($element);
        });
        
        $elements.each(function(){
            $element = $(this);
            $element.removeClass('has-error');
            // $('.has-error', $element).removeClass('has-error');
            $('.qtiped', $element).removeClass('qtiped').trigger('remove_qtip').removeData('errored QT');
            $(':input', $element).unbind('.error');
            // $('.form-group .error', $element).remove();
            $('.error', $element).remove();
            $('.additional_errors', $element).empty().hide();
//          $('.btn-danger', $element).removeClass('btn-danger');
        });
    }
};
