
function initProduct() {
    $(".pinterest_url_workaround").each(function() {
        var $this = $(this);
        $this.attr("href", $this.attr("href") + "?url=" + $this.data("url") + "&media=" + $this.data("media") + "&description=" + $this.data("description"));
    });

    $(".js-product-zoomableImage").on("click", function() {
        if (!$(this).closest(".product").hasClass("product-withoutDecor")) {
        //     $(this).toggleClass("product-zoomableImage-zoomed");

            var zoom_image = $(this).find("img").data("zoomed");
        //     $(this).find("img").attr("src", zoom_image);

        //     if ($(this).hasClass("product-zoomableImage-zoomed")) {
        //         $(this).find(".product-zoomIcon").removeClass("icon-zoom-in").addClass("icon-zoom-out");
        //     } else {
        //         $(this).find(".product-zoomIcon").removeClass("icon-zoom-out").addClass("icon-zoom-in");
        //     }
            $.fancybox({
                content: '<img class="zoomedProduct" src="'+zoom_image+'" alt="" />',
                padding: 0,
                closeBtn: false,
                afterLoad: function() {
                    $(".fancybox-outer").append('<a class="zoomedProduct-close icon-close" href="javascript:;"></a>');
                }
            });
        }
    });

    function fixProductInfoValues() {
        $(".product-infoValue-first").removeClass("product-infoValue-first");
        $(".product-infoValue-last").removeClass("product-infoValue-last");

        $(".js-product-infoValues").each(function() {
            var top = 0;
            var $last = undefined;

            $(this).find(".product-infoValue").first().addClass("product-infoValue-first");
            $(this).find(".product-infoValue").last().addClass("product-infoValue-last");

            $(this).find(".product-infoValue").each(function() {
                var _top = $(this).offset().top;

                if (_top != top) {
                    $(this).addClass("product-infoValue-first");
                    if ($last != undefined) {
                        $last.addClass("product-infoValue-last");
                    }
                }

                top = _top;
                $last = $(this);
            });
        });
    }

    doEventPeriodically($(window), "resize", 100, fixProductInfoValues);
    fixProductInfoValues();

    $('.js-openEstimateFancybox').fancybox({
        padding: 0
    });
}
