
function initZoomableProducts() {
    $(".js-zoomableProduct").on("click", function(e) {
        e.preventDefault();
        
        var image_src = $(this).data("src");
        
        $.fancybox({
            content: '<img class="zoomedProduct" src="'+image_src+'" alt="" />',
            padding: 0,
            closeBtn: false,
            afterLoad: function() {
                $(".fancybox-outer").append('<a class="zoomedProduct-close icon-close" href="javascript:;"></a>');
            }
        });
    });
    
    $("body").on("click", ".zoomedProduct-close", function() {
        $.fancybox.close();
    });
}
