
function initPage404() {
    $(".js-page404-input").focus();
    
    $(".js-page404-form").on("submit", function(e) {
        e.preventDefault();
        
        var value = $(".js-page404-input").val();
        
        if (value) {
            var hash = "search" + URLON.stringify(value);
            var link = $(this).attr("action");
            
            window.location = link + "#" + hash;
        }
    });
}
